import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/mdrenov/logo_72dpi_sans-phrase.png'


const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
        // style={{ backgroundColor: "#dfe0df" }}
      >
        <div className="container">

          <div className="navbar-brand is-fixed-top" >
            <Link to="/" className="navbar-item" title="Logo" style={{ paddingTop: '0px' }}>
              <img src={logo} alt="Logo MD-renov" style={{ width: '160px' }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-end" style={{ marginBottom : '20px'}}>
              <Link className="navbar-item has-text-centered" activeClassName="active" to="/">
                A propos
              </Link>
              <Link className="navbar-item has-text-centered" activeClassName="active" to="/prestations">
                Prestations
              </Link>
              <Link className="navbar-item has-text-centered" activeClassName="active" to="/realisations">
                Réalisations
              </Link>
              <Link className="navbar-item has-text-centered" activeClassName="active" to="/contact">
                Contact
              </Link>

              {/* <a title="facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Atelier-forma-grenoble-110726567936480">
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: '1.3em', height: '1.3em', marginLeft: '0.5rem' }}
                />
              </a>
              <a title="instagram" target="_blank" rel="noreferrer" href="https://www.instagram.com/atelierforma.grenoble">
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: '1.3em', height: '1.3em', marginLeft: '1rem' }}
                />
              </a> */}
            </div>

            {/* <div className="column is-one-fifth social">
          </div> */}
          </div>
        </div>
      </nav >
    )
  }
}

export default Navbar
