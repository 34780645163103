import React from 'react'
import { Link } from 'gatsby'

import facebook from '../img/social/facebook2.svg'
import linkedin from '../img/social/linkedin.svg'

const Footer = class extends React.Component {
  render() {
    return (
        <footer className="footer" style={{ paddingTop: "2rem"}}>
          <div className="content has-text-centered"
              // style={{ backgroundColor: "#dfe0df" }}
          >
            <div className="container">
              <div className="footer-text-align">
                <div style={{ maxWidth: '100vw' }} className="columns has-text-centered-mobile">
                  <div className="column is-3 footerBorder">
                    <Link to="/mention-legale">
                      <h5 style={{ color : "#b3722d" }}>MENTIONS LÉGALES</h5>
                    </Link>
                    <Link to="/confidentialite">
                      <h5 style={{ color : "#b3722d" }}>POLITIQUE  DE CONFIDENTIALITÉ</h5>
                    </Link>
                  </div>
                  <div className="column is-3 has-text-grey footerBorder">
                    <h5 style={{ color : "#b3722d" }}>CONTACT</h5>
                    <p>07 67 71 15 93 </p>
                    <p>md.renov38@gmail.com</p>
                    <Link className="has-text-orange" style={{ fontSize: '1em' }} to="/contact">
                      Formulaire de contact
                    </Link>
                  </div>
                  <div className="column is-3 has-text-grey footerBorder">
                    <h5 style={{ color : "#b3722d" }}>GÉRANT ET ADRESSE</h5>
                    <p>Matthieu Deswaerte</p>
                    <a className="has-text-grey" style={{ fontSize: '1em', marginBottom: '1em' }} target="_blank" rel="noreferrer" href="https://www.google.com/maps?client=firefox-b-d&sxsrf=ALeKk00fg5idBgXEV4Lep26OfGXeHJTkUQ:1613067526290&q=15+chemin+de+Boulun+%E2%80%93+38210+Tullins&gs_lcp=CgZwc3ktYWIQAzICCCY6BwgAEEcQsANQjglYjglg_gpoAXACeACAAXmIAdYBkgEDMS4xmAEAoAEBqgEHZ3dzLXdpesgBBcABAQ&uact=5&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjikLzouOLuAhUID2MBHURWDKQQ_AUoAXoECAcQAw">15 chemin de Boulun <br />
                      38210 Tullins</a>
                    <p></p>
                  </div>
                  <div className="column is-3 has-text-centered-mobile footerBorder">
                    <h5 style={{ color : "#b3722d" }}>RÉSEAUX SOCIAUX</h5>
                    <a title="instagram" target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/md-r%C3%A9nov/">
                      <img
                          src={linkedin}
                          alt="Instagram"
                          style={{ width: '3em', height: '3em', fill: "black", path: "black" }}
                      />
                    </a>
                    <a title="facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/MD.Renov38">
                      <img
                          src={facebook}
                          alt="Facebook"
                          style={{ width: '3em', height: '3em' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-centered">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column is-12 has-text-centered" style={{ fontSize: '0.8rem' }}>

                <p>© 2022 COPYRIGHT - MD Renov  |
                  Site : <a target="_blank" rel="noreferrer" href="https://www.malt.fr/profile/timrobert1" className="has-text-orange" style={{ fontSize: '1em' }}>Tim ROBERT</a></p>
              </div>
              <div className="column is-6  has-text-centered">
              </div>
            </div>
          </div>
        </footer>
    )
  }
}

export default Footer
